import "./App.css";
import tubes from "./tubes.png";

function App() {
  return (
    <div className="App">
      <img src={tubes} alt="'timeout' tubes" width="500px" />
    </div>
  );
}

export default App;
